.addToCart:disabled {
    opacity: .65 !important;
    cursor: not-allowed !important;
}

.addToCart {
    color: #FFF;
    font-family: Poppins;
    /* font-size: 16px; */
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #E86669;
    border: 0;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0.6vw 2vw;
    margin-right: 0.8vw;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    cursor: pointer;
    animation-delay: 0.8s;
    width: 12rem;
}