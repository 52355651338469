h2 {
    color: #F47274;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.row .col-sm-2:nth-child(1),
.row .col-sm-2:nth-child(2),
.row .col-sm-2:nth-child(3),
.row .col-sm-2:nth-child(4),
.row .col-sm-2:nth-child(5) {
    background-color: #f5f5f5;
    /* Replace with your desired background color */
    padding: 1rem;
    /* display: block; */
    /* text-align: center; */
}

.border-right {
    /* border-right: 1px solid #dee2e6!important; */
    position: relative;
}

.border-right::before {
    content: "";
    position: absolute;
    top: 1rem;
    /* Adjust the top position as needed */
    bottom: 1rem;
    /* Adjust the bottom position as needed */
    right: 0;
    border-right: 1px solid #dee2e6 !important;
}

.description-block {
    display: block;
    margin: 10px 0;
    text-align: center;
}

.description-block>.description-header {
    font-size: 16px;
    font-weight: 600;
    color: #F47274;
    margin: 0;
    padding: 0;
}

.description-block>.description-text {
    text-transform: uppercase;
}


.row .col-sm-2:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.row .col-sm-2:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: none;
}

.nav-pills .nav-link {
    background: #f5f5f5;
    border: 0;
    border-radius: 8px 8px 0px 0px;
}

.nav-link {
    display: block;
    padding: 1rem 2rem;
    color: #000;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    border-radius: 20px 20px 0px 0px;
    background: #F5F5F5;
    font-size: 20px;
    /* font-weight: 600; */
}

/* .nav-link :hover {
    color: #F47274 !important;
} */

/* .nav-link:focus,  */
.nav-link:hover {
    color: #000 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    font-size: 20px;
    /* font-weight: 600; */
    color: #fff;
    border-radius: 8px 8px 0px 0px;
    background: #F47274;
    padding: 1rem 2rem;
}

.tab-content {
    padding: 30px 0px 0px;
}

.tab-content>.active {
    display: block;
    background-color: transparent !important;

}

#sellerProfile .member {
    margin-bottom: 20px;
    overflow: hidden;
    /* text-align: center; */
    border-radius: 12px;
    background: #fff;
    /* box-shadow: 0px 2px 15px rgba(63, 187, 192, 0.1); */
    box-shadow: 0px 2px 15px #dee2e6;
    ;
}

#sellerProfile .member .member-img {
    position: relative;
    overflow: hidden;
}

#sellerProfile .member .member-img .image-item {
    width: 100%;
    height: 18vw;
    object-fit: cover;
}

#sellerProfile .member .member-info {
    padding: 25px;
}

#sellerProfile .member .member-info h4 {
    color: #F47274;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#sellerProfile .member .member-info h5 {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#sellerProfile .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
}

#sellerProfile .member .member-info p {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

#sellerProfile .member .member-info button {
    color: #FFF;
    font-family: Poppins;
    /* font-size: 16px; */
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #E86669;
    border: 0;
    letter-spacing: 1px;
    display: inline-block;
    padding: 0.6vw;
    margin-right: 0.8vw;
    transition: 0.4s;
    border-radius: 50px;
    cursor: pointer;
    animation-delay: 0.8s;
    width: -webkit-fill-available;
}

#sellerProfile .member .member-info i {
    color: #F47274;
    cursor: pointer;
}


#sellerProfile .card-body {
    position: relative;
    border: 1px solid #f47274;
    border-radius: 0 8px 8px 8px;
}

#sellerProfile .date {
    color: gray;
}

#sellerProfile p {
    color: #000;
}

.ribbon-2 {
    --f: 0px; /* control the folded part*/
    --r: 35px; /* control the ribbon shape */
    --t: 60px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 40px var(--f) calc(25px + var(--r));
    /* padding: 10px 10px var(--f) calc(10px + var(--r)); */
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: #F47274;
    color: #FFF;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  }