#our-guarantee {
    background: #fff;
    color: #000;
    padding: 60px 0 40px 0;
}

#our-guarantee h2 {
    color: #000;
    font-family: Poppins;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    /* line-height: 85px; */
    letter-spacing: 1px;
}

#our-guarantee .card-box {
    box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
    padding: 3vw;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #CDCDCD;
}

#our-guarantee .card-box i {
    display: block;
    font-size: 3vw;
    color: #F47274;
    float: left;
}

#our-guarantee .card-box span {
    font-size: 2vw;
    line-height: 24px;
    display: block;
    font-weight: 700;
    color: #000;
    margin-left: 1.5rem;
}

#our-guarantee .card-box p {
    padding: 30px 0 0 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 1vw;
}