.my-dropdown{
    /* background-color: #000000!important; */
    border: none!important;
}
.ant-dropdown-menu{
  /* background-color: #000000!important; */
  border-radius: none!important;
}
.my-dropdown .ant-dropdown-menu-item {
    /* background-color: #000000!important; */
    color: #ffffff!important;
    border: none!important;
  }
  
  .my-dropdown .ant-dropdown-menu-item:hover {
    background-color: grey!important;
  }