#add_product .card {
    padding: 4rem;
}

#add_product h2 {
    color: #000;
}

#add_product .add_image {
    padding: 3rem;
    background-color: #f2f2f2;
    border-radius: 2rem;
}

.form-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 0.5rem;
    border-top: none;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.form-footer .btn-submit {
    background-color: #F47274 !important;
    color: #fff !important;
    border-radius: 25px;
    padding: 0.375rem 1.75rem;
}

.form-footer .btn-light {
    text-decoration: underline;
    border-radius: 25px;
    padding: 0.375rem 1.75rem;
}

.form-footer .btn-light:hover {
    color: #000;
    background-color: lightgray;
    border-color: #f9fafb;
    text-decoration: none;
}