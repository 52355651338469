#view-article {
    width: 100%;
    background: #fff;
    color: #000;
    background-size: cover;
    overflow: hidden;
  }
  
  .view-article-hero {
    width: 100%;
    height: 40vh;
    /* background: url(../img/cloth.webp) top center; */
    background: lightpink;
    /* background: linear-gradient(130deg, rgba(250, 231, 237, 1), rgba(250, 229, 236, 1), rgba(251, 209, 223, 0.97), rgba(247, 212, 223, 1)); */
    background-size: cover;
    overflow: hidden;
    position: relative;
  }
  
  @media (min-width: 1024px) {
    .view-article-hero {
      background-attachment: fixed;
    }
  }
  
  .view-article-hero:before {
    content: "";
    /* background: rgba(0, 0, 0, 0.5); */
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #view-article .view-article-hero .view-article-hero-container {
    position: absolute;
    bottom: 0;
    left: 0;
    /* top: 90px; */
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* text-align: center; */
    padding: 0 15px;
  }
  
  @media (max-width: 991px) {
    .view-article-hero .view-article-hero-container {
      top: 70px;
    }
  }
  
  .view-article-hero h3 {
    color: #FFF;
    font-family: Poppins;
    /* font-size: 40px; */
    font-size: 2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  @media (max-width: 991px) {
    .view-article-hero h2 {
      font-size: 34px;
    }
  }
  
  @media (max-width: 1199.98px) {
    .view-article-hero h2 {
      font-size: 40px;
    }
  }

  #view-article .date {
    position: absolute;
    bottom: 0;
    right: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 15px;
    background: #F47274;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.breadcrumb-item a {
    color: #A5A5A5;
}

.breadcrumb-item.active {
    color: gray !important;
    background-color: #fff;
}

.opacity-5 {
    opacity: 0.5 !important;
}