#orders .search {
    position: relative;
}

#orders .card {
    padding: 0;
}

#orders .search .search-input {
    background-color: #fff;
    height: 46px;
    width: 100%;
    border-radius: 2rem;
    border: 1px solid #cdcdcd;
    color: #666;
    font-size: 16px;
    text-indent: 10px;
    padding: 0px 15px 0px 45px;
}

#orders .search i {
    position: absolute !important;
    color: #666;
    left: 20px !important;
    top: 16px !important;
    width: 18px !important;
    height: 18px !important;
    font-size: 16px !important;
}

#orders .badgeComp {
    background-color: #F6ECD0;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    width: 80%;
    text-transform: uppercase;
}

#orders .badgeInit {
    background-color: #DCDCDC;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    width: 80%;
    text-transform: uppercase;
}

#orders table,
thead,
tbody,
th,
td {
    border: none !important;
}

#orders table thead {
    background-color: #F5F5F5 !important;
    color: #000;
    border-radius: 10px;
    /* line-height: 40px; */
}

#orders table th {
    background: none !important;
    color: #000;
}

.table-bordered, td, th, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
    vertical-align: middle;
    white-space: nowrap;
    padding: 0.7rem 0;
}

/* #orders .table th:nth-child(3),
.table td:nth-child(3),
.table th:nth-child(4),
.table td:nth-child(4),
.table th:nth-child(5),
.table td:nth-child(5),
.table th:nth-child(6),
.table td:nth-child(6) {
    width: 15%;
} */

#orders .btn-locker {
    color: #FFF;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
}

#orders .btn-locker:hover {
    background-color: #E86669;
    color: #FFF;
}

#orders .btn-ninja {
    color: #F47274;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    /* padding: 4px 60px; */
    transition: 0.4s;
    width: 80%;
    text-transform: uppercase;
}

#orders .btn-ninja:hover {
    background-color: #f47274;
    color: #fff;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #686868;
    border-radius: 0.3rem;
    outline: 0;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-weight: 600;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-bottom: none;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0rem 2rem;
    background-color: #fff;
    border: none;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem 2rem;
    border-top: none;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
    margin-left: 0;
}

#orders .modal-content .fa-star {
    font-size: 30px !important;
    color: #CDCDCD !important;
    cursor: pointer;
}

#orders .btn-submit {
    background-color: #F47274 !important;
    color: #fff !important;
    border-radius: 10px;
    padding: 0.375rem 1.75rem;
}

#orders .btn-light {
    text-decoration: underline;
    border-radius: 10px;
    /* padding: 0.375rem 1.75rem; */
}